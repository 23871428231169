<template>
  <div>
    <BrandChooseWindow />

    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo-all-dark.png" />
    </Header>
    <div class="bg_image bg_image--16 d-none d-md-block">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient"></h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <div class="rn-column-area rn-section-gap mt--150"> -->
    <v-container>
      <v-row>
        <v-col
          class="
            mt--20
            mb--100
            mb_sm--20
            order-xl-0 order-lg-0 order-md-0 order-sm-1 order-xs-1
          "
          xl="5"
          lg="5"
          md="5"
          sm="12"
          cols="12"
        >
          <h2 class="heading-title mt--50 pt--20 mt_sm--0">Πληροφορίες</h2>
          <p>
            Σας καλωσορίζουμε στο loyalty program Health_e Bonus Card
          </p>
          <p>
            Το Hellenic Healthcare Group, ο μεγαλύτερος ιδιωτικός Όμιλος
            υπηρεσιών υγείας στην Ελλάδα, σας επιβραβεύει για την εμπιστοσύνη
            σας και σας καλωσορίζει σε ένα premium δίκτυο υγείας μέσα από την
            εφαρμογή Health_e Bonus Card.
          </p>
          <h3 class="heading-title mt--50">Ποιοι είμαστε</h3>
          <p>
            Είμαστε το Hellenic Healthcare Group, ο μεγαλύτερος ιδιωτικός όμιλος
            υπηρεσιών υγείας στην Ελλάδα. Με έξι κορυφαία Θεραπευτήρια και
            τέσσερα διαγνωστικά κέντρα παρέχουμε ολοκληρωμένη ιατρική περίθαλψη
            για κάθε θέμα υγείας, από τη γέννηση έως την τρίτη ηλικία, 24 ώρες
            το 24ωρο, 365 ημέρες το χρόνο.
          </p>
          <h3 class="heading-title mt--50">To πρόγραμμα loyalty του HHG</h3>
          <p>
            Με κάθε σας συναλλαγή (π.χ. επίσκεψη γιατρού, εξετάσεις, νοσηλεία)
            στα Θεραπευτήρια Υγεία, Metropolitan Hospital, Μητέρα, Metropolitan
            General, Λητώ, Creta InterClinic, στα Διαγνωστικά Κέντρα HealthSpot,
            στο Υγεία IVF και στο εργαστήριο ALab, μαζεύετε πόντους, ανεβαίνετε
            βαθμίδα και ανταμείβεστε για την επιλογή σας. στη χώρα μας.
          </p>
          <p>
            Η κάρτα Health_e Bonus σας δίνει τη δυνατότητα να απολαμβάνετε
            ξεχωριστά προνόμια με ειδικές παροχές και μοναδικές προσφορές στα
            Θεραπευτήρια, τα Διαγνωστικά Κέντρα και το εργαστήριο ALab του
            Ομίλου HHG.
          </p>
          <p>
            Γραμμή εξυπηρέτησης για τους κατόχους της Health_e Bonus Card:
            <b>210 300 66 34</b>
          </p>
          <h3 class="heading-title mt--50">
            Τι προσφέρει η εφαρμογή στον κάτοχο της κάρτας
          </h3>
          <ul>
            <li>
              Υπηρεσίες υγείας σε προνομιακές τιμές ή απολύτως δωρεάν στον
              μεγαλύτερο ιδιωτικό Όμιλο υπηρεσιών υγείας στην Ελλάδα.
            </li>
            <li class="mt--5">
              Ενημέρωση για τους πόντους, τα προνόμια, τις νέες υπηρεσίες σε
              κάθε Θεραπευτήριο και Διαγνωστικό κέντρο του Oμίλου, καθώς και
              ενδιαφέρουσα αρθρογραφία για θέματα υγείας.
            </li>
            <li class="mt--5">
              Γρήγορη, εύκολη και ασφαλή πρόσβαση στα στοιχεία σας.
            </li>
            <!-- <li class="mt--5">
              Ενημέρωση για τους πόντους, τα προνόμια και τις υπηρεσίες σε κάθε
              Θεραπευτήριο και διαγνωστικό κέντρο του Ομίλου.
            </li> -->
            <li class="mt--5">
              Απόλυτη προστασία των προσωπικών δεδομένων και των δεδομένων
              υγείας, αφού εφαρμόζονται αυστηρά πρωτόκολλα ασφαλείας.
            </li>
          </ul>
          <h3 class="heading-title mt--50">
            Πώς να αποκτήσετε τη δωρεάν Health_e Bonus Card
          </h3>
          <!-- <ul>
            <li> -->
          <p>
            Kατεβάστε την εφαρμογή Health_e Bonus Card από το App Store για
            συσκευές iOS και το Google Play για συσκευές Android. <br />
            <a href="www.healthebonus.gr" target="_blank"
              >www.healthebonus.gr</a
            >
          </p>

          <!-- </li>
            <li>
              Ακολουθήστε τις οδηγίες στο
              <a href="https://www.hhg.gr/el/" target="_blank">www.hhg.gr/</a>
            </li>
            <li>
              Κατεβάστε το Health_e Bonus Card app από το App Store για συσκευές
              iOS και το Google Play για συσκευές Android.
            </li>
          </ul> -->
          <h3 class="heading-title mt--50" style="color: red">
            Kατεβάστε την Health_e Bonus Card app
          </h3>

          <v-row>
            <v-col sm="12" md="12" lg="5" xl="4" cols="12">
              <a
                href="https://apps.apple.com/gr/app/health-e-bonus-card/id1589042859"
                target="_blank"
              >
                <v-img
                  style="float: left !important"
                  max-width="180"
                  class="mb--20"
                  src="../assets/images/service/appstore.png"
                ></v-img>
              </a>
            </v-col>
            <v-col sm="12" md="12" lg="5" xl="4" cols="12">
              <a
                href="https://play.google.com/store/apps/details?id=com.roadcube.hellenichealthcaregroup"
                target="_blank"
              >
                <v-img
                  style="float: left !important"
                  max-width="180"
                  class="mb--20"
                  src="../assets/images/service/googleplay.png"
                ></v-img>
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="
            mt--60
            mb--60
            mt_sm--0
            mb_sm--30
            order-xl-1 order-lg-1 order-md-1 order-sm-0 order-xs-0
          "
          xl="7"
          lg="7"
          md="7"
          sm="12"
          cols="12"
        >
          <v-img src="../assets/images/pronomia/hhg_circle.png"></v-img>
        </v-col>
      </v-row>
      <h3 class="heading-title mt--50">Πώς γίνεται η εγγραφή</h3>
      <p>
        Η διαδικασία εγγραφής είναι απλή και σύντομη και ολοκληρώνεται οnline
        από κινητή ή σταθερή συσκευή χωρίς να απαιτείται η επίσκεψη σε
        Θεραπευτήριο ή Διαγνωστικό Κέντρο του Ομίλου HHG.
      </p>
      <p>
        Κατά την εγγραφή σας στην εφαρμογή Health_e Bonus Card, χρειάζεται να
        έχετε:
      </p>
      <ul>
        <li>ενεργό προσωπικό e-mail</li>
        <li>προσωπικό αριθμό κινητού τηλεφώνου</li>
        <li>αριθμό μητρώου κοινωνικής ασφάλισης (ΑΜΚΑ)</li>
      </ul>
      <p class="mb--40">
        Αφού κατεβάσετε την εφαρμογή Health_e Bonus Card και ακολουθήσετε τις
        οδηγίες ενεργοποίησης γίνεστε, αυτόματα κάτοχος της ψηφιακής κάρτας
        Health_e Bonus White.
      </p>
    </v-container>
    <!-- </div> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/HeaderOnePage";
import Footer from "@/components/footer/FooterTwo";
import BrandChooseWindow from "@/components/brand/BrandChooseWindow";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Header,
    Footer,
    BrandChooseWindow,
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {
    await this.getUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapActions(["getToken"]),
  },
};
</script>
<style scoped>
.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 55px;
}
.theme--light.v-image {
  float: left;
  margin-left: 10px;
}
</style>
